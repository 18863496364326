import React, { useState } from 'react';
import './CustomCheckbox.css'; // Import your CSS file with custom styles

const CustomCheckbox = () => {
  const [isChecked, setIsChecked] = useState(true); // Initial state for checkbox

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle checkbox state
  };

  return (
    <div className="custom-form">
      <label className="custom-container"> I accept the terms & conditions.
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className="custom-checkmark"></span>
      </label>
    </div>
  );
}

export default CustomCheckbox;
