import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './TestimonialsSection.css';

// Sample testimonials data
const testimonialsData = [
  {
    id: 1,
    name: 'John Doe',
    position: 'Real Estate Investor',
    image: 'https://randomuser.me/api/portraits/men/1.jpg',
    quote:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    rating: 5,
  },
  {
    id: 2,
    name: 'Jane Smith',
    position: 'Home Buyer',
    image: 'https://randomuser.me/api/portraits/women/2.jpg',
    quote:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.",
    rating: 4,
  },
  {
    id: 3,
    name: 'Michael Johnson',
    position: 'Property Developer',
    image: 'https://randomuser.me/api/portraits/men/3.jpg',
    quote:
      "Sunt in culpa qui officia deserunt mollit anim id est laborum. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    rating: 4,
  },
  {
    id: 4,
    name: 'Alice Brown',
    position: 'Home Buyer',
    image: 'https://randomuser.me/api/portraits/women/3.jpg',
    quote:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.",
    rating: 5,
  },
  {
    id: 5,
    name: 'Robert Wilson',
    position: 'Real Estate Investor',
    image: 'https://randomuser.me/api/portraits/men/4.jpg',
    quote:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos.",
    rating: 3,
  },
  {
    id: 6,
    name: 'Emily Clark',
    position: 'Home Buyer',
    image: 'https://randomuser.me/api/portraits/women/4.jpg',
    quote:
      "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius.",
    rating: 5,
  },
  {
    id: 7,
    name: 'James Miller',
    position: 'Property Developer',
    image: 'https://randomuser.me/api/portraits/men/5.jpg',
    quote:
      "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi.",
    rating: 4,
  },
  {
    id: 8,
    name: 'Charlotte Wilson',
    position: 'Real Estate Investor',
    image: 'https://randomuser.me/api/portraits/women/5.jpg',
    quote:
      "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem.",
    rating: 3,
  },
  {
    id: 9,
    name: 'Liam Martinez',
    position: 'Home Buyer',
    image: 'https://randomuser.me/api/portraits/men/6.jpg',
    quote:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos.",
    rating: 4,
  },
  // Add more testimonials as needed
];

const TestimonialsSection = () => {
  return (
    <section className="testimonials-section">
      <div className="container">
        <div className="section-title text-center mb-5">
          <h2>What Our Customers Say?</h2>
        </div>
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          className="mySwiper"
        >
          {testimonialsData.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="testimonial-item">
                <div className="stars">{'★'.repeat(testimonial.rating)}</div>
                <div className="testimonial-content">{testimonial.quote}</div>
                <div className="testimonial-author">
                  <img src={testimonial.image} alt={testimonial.name} />
                  <div className="author-details">
                    <h4>{testimonial.name}</h4>
                    <p>{testimonial.position}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default TestimonialsSection;
