import React from 'react';
import './TermsAndConditions.css'; // Import your CSS file with custom styles
import CustomCheckbox from './accept'; // Assuming this is where your custom checkbox component is imported

const TermsAndConditions = () => {
  return (
    <div className="page terms-and-conditions">
      <div id="terms-and-conditions">
        <h1>Terms And Conditions</h1>
        <ol>
          <li>
            <b>SELLING RIGHTS AND OBLIGATIONS</b>
            <ol>
              <li>
                As a seller of real estate, you agree to provide accurate information about the property being sold.
              </li>
              <li>
                By listing your property on our platform, you grant us the right to display and promote your property.
              </li>
              <li>Any rights not expressly granted herein are reserved by the platform.</li>
              <li>You may not:
                <ol>
                  <li>Engage in fraudulent activities or misrepresentations about the property.</li>
                  <li>Interfere with the platform's operations or other users' listings.</li>
                  <li>Use the platform for illegal purposes or activities.</li>
                  <li>Violate any local or international laws regarding property sales.</li>
                  <li>Attempt to reverse-engineer or modify any part of the platform.</li>
                  <li>Disrupt the normal operation of our services.</li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <CustomCheckbox />
    </div>
  );
}

export default TermsAndConditions;
