import React from 'react';
import './ServicesComponent.css';

const servicesData = [
  { title: 'INSPECTIONS', image: 'https://premiergrouproofs.com/wp-content/uploads/2023/02/Icon-Light-Grey-Circle-Red-Checkmark.svg' },
  { title: 'REPAIRS', image: 'https://premiergrouproofs.com/wp-content/uploads/2023/02/Icon-Light-Grey-Circle-Red-Checkmark.svg' },
  { title: 'REPLACEMENTS​', image: 'https://premiergrouproofs.com/wp-content/uploads/2023/02/Icon-Light-Grey-Circle-Red-Checkmark.svg' },
  { title: 'INSURANCE​', image: 'https://premiergrouproofs.com/wp-content/uploads/2023/02/Icon-Light-Grey-Circle-Red-Checkmark.svg' },
  { title: 'FLAT ROOFING', image: 'https://premiergrouproofs.com/wp-content/uploads/2023/02/Icon-Light-Grey-Circle-Red-Checkmark.svg' },
  { title: 'TPO ROOFING', image: 'https://premiergrouproofs.com/wp-content/uploads/2023/02/Icon-Light-Grey-Circle-Red-Checkmark.svg' },
  { title: 'PREVENTIVE MAINTENANCE', image: 'https://premiergrouproofs.com/wp-content/uploads/2023/02/Icon-Light-Grey-Circle-Red-Checkmark.svg' },
  { title: '24/7 EMERGENCY REPAIRS', image: 'https://premiergrouproofs.com/wp-content/uploads/2023/02/Icon-Light-Grey-Circle-Red-Checkmark.svg' },
];

const Features = () => {
  return (
    <div className="services-container">
      <div className="services-column">
        {servicesData.slice(0, 4).map((service, index) => (
          <div key={index} className="service-box">
            <div className="service-image">
              <img src={service.image} alt={service.title} loading="lazy" />
            </div>
            <div className="service-title">
              <h3>{service.title}</h3>
            </div>
          </div>
        ))}
      </div>
      <div className="services-column">
        {servicesData.slice(4).map((service, index) => (
          <div key={index} className="service-box">
            <div className="service-image">
              <img src={service.image} alt={service.title} loading="lazy" />
            </div>
            <div className="service-title">
              <h3>{service.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
