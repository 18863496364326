import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./SignatureComponent.css";

const SignatureComponent = ({ downPayment, packPrice }) => {
  const [isDrawing, setIsDrawing] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(null);
  const sigCanvas = useRef({});

  const clearCanvas = () => {
    sigCanvas.current.clear();
  };

  const saveSignature = () => {
    if (isDrawing) {
      const dataUrl = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "signature.png";
      link.click();
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setUploadedImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="signature-component">
      {downPayment ? (
        <div className="downPayment">
          <p>
          Selected Package Price: $
            <em style={{ color: "orangered" }}>
              <b>{packPrice}</b>
            </em>
          </p>
          <p>
            Down Payment Accepted: $
            <em style={{ color: "orangered" }}>
              <b>{downPayment}</b>
            </em>
          </p>
        </div>
      ) : null}

      {isDrawing ? (
        <div className="signature-pad">
          <div className="toggle-buttons">
            <button onClick={() => setIsDrawing(true)}>Draw Signature</button>
            {/* <button onClick={() => setIsDrawing(false)}>Upload Image</button> */}
          </div>
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{ width: 250, height: 120, className: "sigCanvas" }}
          />
          <br />

          <div className="rowDiv">
            <button onClick={clearCanvas} className="btn btn-danger">
              Clear
            </button>
            <button className="btn btn-success">Submit</button>
          </div>
        </div>
      ) : (
        <div className="upload-image">
          <input type="file" accept="image/*" onChange={handleImageUpload} />
          <br />

          {uploadedImage && (
            <img src={uploadedImage} alt="Uploaded" className="uploaded-img" />
          )}
          <br />
          <div className="rowDiv">
            <button className="btn btn-success">Submit</button>
          </div>
        </div>
      )}
      {/* <button onClick={saveSignature}>Save as Image</button> */}
    </div>
  );
};

export default SignatureComponent;
