import React, { useEffect, useState } from "react";
import "./style.css";
import FirstSection from "./../../components/HomePage/firstSection/FirstSection";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CompanyDetailsFirst from "../../components/HomePage/secondSection";
import StatsSection from "../../components/HomePage/StatsSection/StatsSection";
import RealEstateDescription from "../../components/HomePage/description";
import StateDescriptionSection from "../../components/HomePage/StateDescriptionSection";
import TestimonialsSection from "../../components/HomePage/TestimonialsSection";
import SignatureComponent from "../../components/HomePage/signature";
import PackagesComponent from "../../components/HomePage/PackagesComponent";
import TermsAndConditions from "../../components/HomePage/termsandconditions";

const Home = () => {
  const { id } = useParams();
  const [selectPackege, setSelectedPackege] = useState(null);
  const [downPayment, setDownPayment] = useState(null);
  const [packPrice, setPackPrice] = useState(null);
  const navigate = useNavigate();
  const [newPackages, setNewPackages] = useState([]);
  const [newEstate, setNewEstate] = useState({});

  const getData = () => {
    axios
      .get(
        "https://camp-coding.site/primiers/api/" +
        "real_states/get_details/" +
        id
      )
      .then((res) => {
        setNewEstate(res?.data?.result);
        try {
          const PUser = JSON.parse(localStorage.getItem("PUser"));
          if (PUser?.user_name?.toLowerCase() != res?.data?.result?.user_name?.toLowerCase()) {

            window.location.href = "/login"
          }
        } catch (err) {
        }
        setNewPackages(res?.data?.result?.packages || []);
      });
  };
  useEffect(() => {
    if (!id || !localStorage.getItem("PLoggedIn")) {
      navigate("/login");
    }
    getData();
  }, []);
  return (
    <div className="home_container">
      <FirstSection newEstate={newEstate} newPackages={newPackages?.sort((a, b) => parseFloat(a?.price) - parseFloat(b?.price))[0]?.price} />
      <StateDescriptionSection newEstate={newEstate} />
      {/* <RealEstateDescription /> */}
      <CompanyDetailsFirst />
      <StatsSection />
      <TestimonialsSection />
      <PackagesComponent
        newPackages={newPackages}
        selectPackege={selectPackege}
        setSelectedPackege={setSelectedPackege}
        setDownPayment={setDownPayment}
        setPackPrice={setPackPrice}
      />
      <TermsAndConditions />
      <SignatureComponent downPayment={downPayment} packPrice={packPrice} />
      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">2024 © Premier Group.</div>
            <div class="col-sm-6">
              <div class="text-sm-end d-none d-sm-block">
                Powered By
                <img
                  // height={160}
                  width={160}
                  style={{ cursor: "pointer" }}
                  class="bigbangLogo"
                  src="https://res.cloudinary.com/duovxefh6/image/upload/v1719493363/bigbang-logo_enxqgb.svg"
                  onClick={() => {
                    window.open("https://its.ae", "_blanck");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
