import React, { useState, useEffect } from 'react';
import "./style.css";

const FirstSection = ({ newEstate, newPackages }) => {
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [validUntil, setValidUntil] = useState(null);

  // Calculate time remaining function
  function calculateTimeRemaining() {
    if (!newEstate || !newEstate.end_date) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const endDate = new Date(newEstate.end_date).getTime();
    const now = new Date().getTime();
    let distance = endDate - now;

    if (distance < 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  // Calculate valid until date function
  function calculateValidUntil() {
    if (!newEstate || !newEstate.end_date) {
      return '';
    }

    const endDate = new Date(newEstate.end_date);
    const validUntilDate = new Date(endDate.getTime()); // Subtract 30 days

    const formattedDate = validUntilDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    return formattedDate;
  }

  // Update time remaining and valid until date every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
      setValidUntil(calculateValidUntil());
    }, 1000);
    return () => clearInterval(interval);
  }, [newEstate]);

  useEffect(() => {
    if (timeRemaining && validUntil) {
      if (!timeRemaining?.days && !timeRemaining?.hours && !timeRemaining?.minutes && !timeRemaining?.seconds) {
        alert("You Proposal Ended");
        window.location.href = "/login"
      }
    }
  }, [timeRemaining])

  return (
    <div className='first_section_container'>
      <div className='overlay'></div>
      <div className='fs_content'>
        <div className='left_side'>
          <h4>THANK YOU</h4>
          <h5>FOR CHOOSING</h5>
          <h3>Premier Group</h3>
          <h6>TO QUOTE YOUR PROJECT</h6>
        </div>
        <div className='right_side'>
          <div className='info'>
            <div className="title hello">HELLO</div>
            <div className='info_data username'>{newEstate?.person_name ? newEstate.person_name : newEstate?.user_name}</div>
          </div>
          <div className='info'>
            <div className="title">YOUR CUSTOM PROPOSAL</div>
            <div className='info_data'>Starting as low as: ${newPackages}</div>
            <p>Per Month OAC-See Details Below</p>
          </div>
          <div className='info'>
            <div className="title">Project Address:</div>
            <div className='info_data'>{newEstate?.location}</div>
          </div>
          <div className='info'>
            <div className="title be_sure">Be sure to accept before pricing expires</div>
            <div className='info_data'>
              {timeRemaining?.days}d {timeRemaining?.hours}h {timeRemaining?.minutes}m {timeRemaining?.seconds}s
            </div>
          </div>
          <p className='bottom_text'>Your Proposal valid until {validUntil}</p>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
