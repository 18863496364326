import React, { useEffect } from "react";
import "./style.css";
import Features from "../features";
function CompanyDetailsFirst() {
  const themeSwitch = document.querySelector("#toggle-theme");
  if (themeSwitch) {
    themeSwitch.addEventListener("change", () => {
      document.body.classList.toggle("dark-theme");
    });

    themeSwitch.addEventListener("click", function () {
      this.parentElement.classList.toggle("checked");
    });
  }

  return (
    <div className="banner d-flex align-items-center">
      <div className="banner-left">
        <label className="switch d-flex">
          <input id="toggle-theme" type="checkbox" />
          <span className="light-txt">light</span>
          <span className="dark-txt">dark</span>
        </label>
        <h1>PREMIER GROUP</h1>
        <div className="inner-desc">
          <p>
            At Premier Group, we have extensive experience in the commercial
            roofing industry. We will find the best roofing system for your
            property while still working within your budget. Our team has the
            expertise to install many of these systems for your roof. From
            retail locations to metal roofing systems, the team at Premier Group
            has you covered.
          </p>
        </div>
        <Features/>
      </div>
      <div className="banner-right d-flex">
        <div className="family">
          <img
            src="https://yudiz.com/codepen/real-estate/property-img-one.png"
            alt="img"
            className="img-fluid"
          />
          <img
            src="https://yudiz.com/codepen/real-estate/property-img-two.png"
            alt="img"
            className="img-fluid"
          />
          <div className="banner-right-inner">
            <h2>42k+</h2>
            <span>
              satisfied <br /> family
            </span>
            <img
              src="https://yudiz.com/codepen/real-estate/heart-icon.svg"
              alt="heart-icon"
              className="img-fluid"
            />
          </div>
          <img
            src="https://yudiz.com/codepen/real-estate/property-img-three.png"
            alt="img"
            className="img-fluid"
          />
          <img
            src="https://yudiz.com/codepen/real-estate/property-img-four.png"
            alt="img"
            className="img-fluid"
          />
        </div>
        <div className="sale">
          <img
            src="https://yudiz.com/codepen/real-estate/property-img-five.png"
            alt="img"
            className="img-fluid"
          />
          <img
            src="https://yudiz.com/codepen/real-estate/property-img-six.png"
            alt="img"
            className="img-fluid"
          />
          <div className="banner-right-inner">
            <h2>30k+</h2>
            <span>
              Available <br /> Unit for Sale
            </span>
            <img
              src="https://yudiz.com/codepen/real-estate/unit-icon.svg"
              alt="unit-icon"
              className="img-fluid"
            />
          </div>
          <img
            src="https://yudiz.com/codepen/real-estate/property-img-seven.png"
            alt="img"
            className="img-fluid"
          />
          <img
            src="https://yudiz.com/codepen/real-estate/property-img-eight.png"
            alt="img"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
}

export default CompanyDetailsFirst;
