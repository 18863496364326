import React from "react";
import "./DescriptionImageTerms.css";

const DescriptionImageTerms = ({ newEstate }) => {
  return (
    <div className="description-image-terms">
      <div className="description">
        <h2>Beautiful Real Estate</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: newEstate?.description ? newEstate?.description : "<p></p>",
          }}
        ></p>
      </div>
      <div className="image-container">
        <img
          src={newEstate?.image}
          alt="Real Estate"
          height={300}
          style={{ objectFit: "contain", height: "300px" }}
        />
      </div>
      <div className="terms">
        <h3>Terms and Conditions</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: newEstate?.terms ? newEstate?.terms : "<p></p>",
          }}
        ></p>
      </div>
    </div>
  );
};

export default DescriptionImageTerms;
