import React, { useEffect, useRef } from 'react';
import './StatsSection.css';

function StatsSection() {
  const sectionRef = useRef(null);

  useEffect(() => {
    const counters = document.querySelectorAll('.elementor-counter-number');

    const updateCount = (counter) => {
      const target = +counter.getAttribute('data-to-value');
      const count = +counter.innerText.replace(/,/g, '');
      const increment = target / 200; // Speed of increment, adjust as necessary

      if (count < target) {
        counter.innerText = Math.ceil(count + increment).toLocaleString();
        setTimeout(() => updateCount(counter), 10); // Adjust delay as necessary
      } else {
        counter.innerText = target.toLocaleString();
      }
    };

    const resetCounters = () => {
      counters.forEach(counter => {
        counter.innerText = '0';
      });
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          counters.forEach(counter => updateCount(counter));
        } else {
          resetCounters();
        }
      });
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="elementor-section elementor-top-section elementor-element elementor-element-8a7c406 elementor-section-boxed elementor-section-height-default elementor-section-height-default exad-glass-effect-no wpr-particle-no wpr-jarallax-no wpr-parallax-no wpr-sticky-section-no exad-sticky-section-no animated fadeInUp"
      data-id="8a7c406"
      data-element_type="section"
      data-settings='{"background_background":"classic","animation":"fadeInUp"}'
    >
      <div className="elementor-container elementor-column-gap-default flex-row-wrap">
        <div
          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-1054bb7 exad-glass-effect-no exad-sticky-section-no"
          data-id="1054bb7"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-2f9add6 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-counter"
              data-id="2f9add6"
              data-element_type="widget"
              data-widget_type="counter.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-counter">
                  <div className="elementor-counter-title">
                    COMPLETED <br />
                    PROJECTS
                  </div>
                  <div className="elementor-counter-number-wrapper">
                    <span className="elementor-counter-number-prefix"></span>
                    <span
                      className="elementor-counter-number"
                      data-duration="2000"
                      data-to-value="100000"
                      data-from-value="0"
                      data-delimiter=","
                    >
                      0
                    </span>
                    <span className="elementor-counter-number-suffix">+</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-8809e8a exad-glass-effect-no exad-sticky-section-no"
          data-id="8809e8a"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-c2b9112 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-counter"
              data-id="c2b9112"
              data-element_type="widget"
              data-widget_type="counter.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-counter">
                  <div className="elementor-counter-title">
                    YEARS OF <br />
                    EXPERIENCE
                  </div>
                  <div className="elementor-counter-number-wrapper">
                    <span className="elementor-counter-number-prefix"></span>
                    <span
                      className="elementor-counter-number"
                      data-duration="2000"
                      data-to-value="15"
                      data-from-value="0"
                      data-delimiter=","
                    >
                      0
                    </span>
                    <span className="elementor-counter-number-suffix">+</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-d68a9a9 exad-glass-effect-no exad-sticky-section-no"
          data-id="d68a9a9"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-3987791 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-counter"
              data-id="3987791"
              data-element_type="widget"
              data-widget_type="counter.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-counter">
                  <div className="elementor-counter-title">
                    HIGHLY TRAINED PROFESSIONALS
                  </div>
                  <div className="elementor-counter-number-wrapper">
                    <span className="elementor-counter-number-prefix"></span>
                    <span
                      className="elementor-counter-number"
                      data-duration="2000"
                      data-to-value="100"
                      data-from-value="0"
                      data-delimiter=","
                    >
                      0
                    </span>
                    <span className="elementor-counter-number-suffix">+</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-3dab9f0 exad-glass-effect-no exad-sticky-section-no"
          data-id="3dab9f0"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-6676b07 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-counter"
              data-id="6676b07"
              data-element_type="widget"
              data-widget_type="counter.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-counter">
                  <div className="elementor-counter-title">
                    TOTAL PROJECTS <br />
                    VALUE IN 2022
                  </div>
                  <div className="elementor-counter-number-wrapper">
                    <span className="elementor-counter-number-prefix">$</span>
                    <span
                      className="elementor-counter-number"
                      data-duration="2000"
                      data-to-value="60"
                      data-from-value="0"
                      data-delimiter=","
                    >
                      0
                    </span>
                    <span className="elementor-counter-number-suffix">M+</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StatsSection;
