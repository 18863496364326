import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import './RealEstateDescription.css';

function RealEstateDescription() {
  return (
    <Container className="real-estate-description mt-5">
      
    </Container>
  );
}

export default RealEstateDescription;
